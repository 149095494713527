<template>
	<div class="menu-wrapper">
		<el-menu-item index="logo-link" class="submenu-title-noDropdown logo-link" v-if="sidebar.opened">
			<div v-if="isMobile" @click="closeSideBar" class="close-sidebar">
				<svg-icon icon-class="cancel" />
			</div>
			<img class="logo-img" :src="sideBarLogo" alt="">
			<img class="logo-text" :src="sideBarLogotext" alt="">
		</el-menu-item>
		<el-menu-item index="logo-link" class="submenu-title-noDropdown logo-link-mobile" v-else>
			<img class="logo-img" :src="sideBarLogo" alt="">
		</el-menu-item>
		<router-link key="/dashboard/index" to="/dashboard/index">
			<el-menu-item index="/dashboard/index" class="submenu-title-noDropdown dashboard-button"
				v-if="sidebar.opened">
				<svg-icon icon-class="home" />
				<span class="submenu-title">{{$t('route.dashboard-main')}}</span>
			</el-menu-item>
			<el-menu-item index="/dashboard/index" class="submenu-title-noDropdown dashboard-icon" v-else>
				<svg-icon icon-class="home" />
			</el-menu-item>
		</router-link>
		<template v-for="item in routes">
			<router-link :key="item.path"
				v-if="hasOneShowingChildren(item.children) && !isRestricted(item.meta) && !item.meta.hidden&&item.meta.noDropdown&&item.children.length > 0"
				:to="item.path+'/'+item.children[0].path">
				<el-menu-item :index="item.path+'/'+item.children[0].path"
					:class="{'submenu-title-noDropdown':!isNest}">

					<svg-icon :icon-class="item.meta.icon" v-if='item.meta.icon && !sidebar.opened' />
					<!-- <span class="newFeature" v-if="isNew(item.children[0].meta)">
						<app-icon name="star" /></span> -->
					{{generateTitle(item.children[0].meta.title)}}
				</el-menu-item>
			</router-link>

			<el-submenu :key="item.path" :index="item.name||item.path"
				v-if="!item.meta.noDropdown&&!item.meta.hidden && !isRestricted(item.meta)">
				<template slot="title">
					<svg-icon :icon-class="item.meta.icon" v-if='item.meta.icon && !sidebar.opened' />
					<!-- <span class="newFeature" v-if="isNew(item.meta)">
						<app-icon name="star" /></span> -->
					<span class="menu-title">
						{{generateTitle(item.meta.title)}}
					</span>
					<el-badge
						v-if="typeof item.meta.badge !== 'undefined' && hasBadge(item.meta.badge) && parseInt(badges[item.meta.badge]) > 0"
						:value="badges[item.meta.badge]" :is-dot="isSideBarClosed" :max="99"
						:class="{item: true, isClosed: !sidebar.opened}" />
					<div class="sub-titles" v-html="constructSubTitles(item.children)"></div>
				</template>
				<template v-for="child in item.children" v-if='!child.meta.hidden && !isRestricted(child.meta)'>
					<sidebar-item :is-nest="true" class="nest-menu" v-if='child.children&&child.children.length>0'
						:key="child.path" :routes='[child]'>
					</sidebar-item>
					<router-link v-else :to="item.path+'/'+child.path" :key="child.name">
						<el-menu-item :index="item.path+'/'+child.path">
							<!-- <span class="newFeature" v-if="isNew(child.meta)">
								<app-icon name="star" /></span> -->
							<svg-icon :icon-class="child.meta.icon" v-if='item.meta.icon' />

							<span class="submenu-title">
								{{generateTitle(child.meta.title)}}
							</span>
							<el-badge
								v-if="typeof child.meta.badge !== 'undefined' && hasBadge(child.meta.badge) && parseInt(badges[item.meta.badge]) > 0"
								:value="badges[child.meta.badge]" :max="99" class="item menu-badge" />
						</el-menu-item>
					</router-link>
				</template>
			</el-submenu>
		</template>
		<el-menu-item index="contact" class="submenu-title-noDropdown feedback-button" @click="openFeedback"
			v-if="sidebar.opened">
			<svg-icon icon-class="mail" />
			<span class="submenu-title">{{$t('feedback.menu-item')}}</span>
		</el-menu-item>
		<el-menu-item index="contact" class="submenu-title-noDropdown feedback-icon" @click="openFeedback" v-else>
			<svg-icon icon-class="mail" />
		</el-menu-item>
	</div>
</template>

<script>
	import {
		getBadges
	} from '@/api/front';
	import {
		mapGetters,
		mapState
	} from 'vuex';
	import store from '@/store';
	import has from 'lodash.has';
	import pickBy from 'lodash.pickby';
	import isEmpty from 'lodash.isempty';
	import {
		generateTitle
	} from '@/utils/i18n';
	import sideBarLogo from '@/assets/images/bulina_cel.png';
	import sideBarLogotext from '@/assets/images/marketplace.png';
	import analytics from '@/mixins/analytics';

	export default {
		name: 'SidebarItem',
		props: {
			routes: {
				type: Array
			},
			isNest: {
				type: Boolean,
				default: false
			}
		},
		mixins: [analytics],
		data() {
			return {
				intrebari: 0,
				badges: {},
				sideBarLogo,
				sideBarLogotext
			};
		},
		methods: {
			isNew(item) {
				return typeof item.isNew !== 'undefined' && item.isNew == true;
			},
			isComposedBadge(badge) {
				if (Array.isArray(badge) === false) return false;

				switch (true) {
					case badge.length === 1:
						if (this.badges[badge.join()] > 0) return true;
						else return false;

					case badge.length > 1:
						const badgeKey = badge.join();
						this.badges[badgeKey] = 0;
						for (const i in badge) {
							if (has(this.badges, badge[i])) this.badges[badgeKey] += parseInt(this.badges[badge[i]]);
						}
						if (this.badges[badgeKey] > 0) return true;
						else return false;

					default:
						return false;
				}
			},
			isRestricted(item) {
				const isDemoRestricted = this.isDemo && typeof item.demoRestricted !== 'undefined' && item.demoRestricted;
				if (isDemoRestricted) return true;

				let hasRestriction = false;
				if (this.restrictions && this.restrictions.length > 0 && item.module != null) {
					const module = item.module.split('.');
					const BreakException = {};
					try {
						this.restrictions.some((restriction) => {
							restriction.impact.some((impact) => {
								if (impact.name === item.module || module.includes(impact.name)) {
									hasRestriction = true;
									throw BreakException;
								}
							});
						});
					} catch (e) {
						if (e !== BreakException) {
							console.log(e);
						}
					}
				}

				return hasRestriction;
			},
			hasBadge(badge) {
				return this.isComposedBadge(badge) || has(this.badges, badge);
			},
			loadBadges() {
				if (isEmpty(this.badgesState)) {
					getBadges().then((res) => {
						if (!has(res, 'message')) return false;
						if (isEmpty(res.message)) return false;
						setTimeout(() => {
							this.badges = pickBy(res.message);
							store.dispatch('app/SetBadges', this.badges);
						}, 2000);
						// this.$forceUpdate()
					}).catch((e) => {
						this.$reportToSentry(e, {
							extra: {
								fn: 'getBadges'
							}
						});
						return false;
					});
				} else {
					this.badges = this.badgesState;
				}
			},
			hasOneShowingChildren(children) {
				if (typeof children === 'undefined') return false;
				const showingChildren = children.filter((item) => {
					return !item.meta.hidden;
				});
				if (showingChildren.length === 1) {
					return true;
				}
				return false;
			},
			openFeedback() {
				this.gaEvent('support_open', 'support', 1);
				this.$store.dispatch('app/ToggleFeedBackModal', true);
			},
			generateTitle,
			constructSubTitles(children) {
				const titles = [];
				for (const i in children) {
					if (children[i].meta.hidden) continue;
					titles.push(this.generateTitle(children[i].meta.title));
				}
				return titles.join(', ').replace(/\s+$/, '');
			},
			closeSideBar() {
				this.$store.dispatch('app/ToggleSideBar');
			}
		},
		computed: {
			...mapGetters({
				'badgesState': 'app/badgesState',
				'hasRestrictions': 'user/hasRestrictions',
				'isDemo': 'user/isDemo'
			}),
			...mapState({
				restrictions: (state) => state.user.restrictions,
				device: (state) => state.app.device
			}),
			sidebar() {
				return this.$store.state.app.sidebar;
			},
			isSideBarClosed() {
				return !this.$store.state.app.sidebar.opened;
			},
			isMobile() {
				return this.device === 'mobile';
			}
		},
		mounted() {
			setTimeout(() => {
				this.loadBadges();
			}, 200);
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	@import '../../../../styles/variables.scss';

	.submenu-title {
		padding-left: 16px;
		display: inline-block;
		height: auto;
		line-height: 1;
		padding-right: 3px;
	}

	.close-sidebar {
		position: fixed;
		top: 0;
		right: 1.2rem;
		cursor: pointer;
		z-index: 2019;

		.svg-icon {
			font-size: 28px;
			color: rgb(255, 103, 35);
		}
	}

	.el-badge {
		&.isClosed {
			vertical-align: top;
			left: -4px;
		}

	}

	.logo-link {
		height: initial;
		text-align: center;
		padding-top: 20px;
		padding-bottom: 10px;
		// border-bottom: 1px solid #404854;
	}

	.logo-link-mobile {
		height: initial;
		padding: initial;
		text-align: center;
		padding-left: initial !important;

		.logo-img {
			padding-top: 10px;
			width: 42px;
			height: auto;
		}
	}

	.sub-titles {
		color: #9499A0;
		display: block;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		margin: 2px 0 0;
		opacity: 1;
		overflow: hidden;
		padding: 0;
		text-overflow: ellipsis;
		text-transform: capitalize;
		-webkit-transition: opacity .15s cubic-bezier(0.4, 0.0, 1, 1);
		transition: opacity .15s cubic-bezier(0.4, 0.0, 1, 1);
	}

	.logo-img {
		display: inline-block;
		padding-bottom: 10px;
	}

	.logo-text {
		display: block;
		margin: auto;
	}

	.newFeature {
		position: absolute;
		left: 15px;
		animation: blinker 1.4s linear infinite;
		color: rgb(255, 103, 35);

		.svg-icon {
			font-size: 18px;
			vertical-align: middle;
		}
	}

	.svg-icon {
		font-size: 18px;
		vertical-align: middle !important;
		color: rgb(192, 194, 197);
	}

	.feedback-button,
	.feedback-icon {
		color: rgb(255, 103, 35) !important;
		font-weight: bold;

		i {
			color: rgb(255, 103, 35) !important;
		}
	}


	.dashboard-button,
	.feedback-button {
		padding-left: 24px !important;
	}

	.dashboard-button,
	.dashboard-icon {
		border-top: 1px solid $menuBorder;
		border-bottom: 1px solid $menuBorder;
	}

	.feedback-icon,
	.dashboard-icon {
		text-align: center;
		vertical-align: middle;
	}

	.el-submenu ::v-deep .el-submenu__icon-arrow {
		opacity: 0;
	}

	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
</style>